import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex justify-between mb-4 hidden sm:block" }
const _hoisted_3 = { class: "text-3xl font-bold text-gray-800 dark:text-gray-100" }
const _hoisted_4 = { class: "text-gray-400 font-mediun mt-auto" }
const _hoisted_5 = { ref: "items" }
const _hoisted_6 = { class: "text-sm text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item = _resolveComponent("item")!

  return (_ctx.manga)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.manga.name), 1),
          _createElementVNode("p", _hoisted_4, "chapters: " + _toDisplayString(_ctx.manga.chapters.length), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapters, (chapter, index) => {
            return (_openBlock(), _createBlock(_component_item, {
              key: chapter.id,
              id: `chapter-${index + 1}`,
              name: chapter.name,
              "image-id": chapter.coverPageId,
              "is-read": chapter.isRead,
              to: {
          name: 'chapter',
          params: { mangaName: _ctx.manga.name, chapterNumber: `${chapter.number}` } }
            }, {
              legend: _withCtx(() => [
                _createElementVNode("span", _hoisted_6, " Number of pages read: " + _toDisplayString(chapter.countPagesRead) + " / " + _toDisplayString(chapter.count), 1)
              ]),
              _: 2
            }, 1032, ["id", "name", "image-id", "is-read", "to"]))
          }), 128))
        ], 512)
      ]))
    : _createCommentVNode("", true)
}